<template>
    <div>
        <page-header-layout>
            <div class="main-page-content">
                <el-row :gutter="48">
                    <el-col class="el-col-sm-24 el-col-md-10 el-col-lg-10 el-col-offset-1 ">
                        <div class="el-col-sm-pull-2">
                            <el-button type="primary" size="medium" @click="onSelectQuestion">选文章</el-button>
                        </div>
                    </el-col>

                    <el-col class="el-col-sm-24 el-col-md-12 el-col-lg-24" style="margin-top: 20px">
                        <label class="color-primary">已选文章</label>
                        <ape-table ref="existsApeTable"
                                   :data="articleAddList"
                                   :columns="articleColumns"
                                   :loading="loadingStatus"
                                   :pagingData="existsPagingData"
                                   @switchPaging="switchPaging"
                                   highlight-current-row>
                            <el-table-column
                                    v-if="buttonType== 'icon'"
                                    label="操作">
                                <template slot-scope="scope">
              <span>
                <el-tooltip effect="dark" content="删除" placement="top-start">
                      <el-button type="danger" size="mini"
                                 icon="el-icon-delete"
                                 v-if="userPermissions.indexOf('test_test_paper_delete') != -1"
                            @click="deleteButton(scope.row.id)"
                      >
                      </el-button>
                </el-tooltip>
              </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="操作"
                                    v-if="buttonType== 'text'"
                            >
                                <template slot-scope="scope">
              <span>


                      <el-button type="danger"
                                 size="mini"
                                 v-if="userPermissions.indexOf('test_test_paper_delete') != -1"
                                 @click="deleteButton(scope.row.id)">删除</el-button>

              </span>
                                </template>
                            </el-table-column>
                        </ape-table>

                    </el-col>

                </el-row>
            </div>
        </page-header-layout>

        <ArticlePickDialog
                            ref="articleDialog"
                            :dialogData="dialogDate"
                           :articleDialogData="articleDialogData"
                           @dialog-close="handleArticleDialogClose"
                           @dialog-confirm="handleArticleDialogConfirm"
        ></ArticlePickDialog>
    </div>

</template>

<script>
    import PageHeaderLayout from '@/layouts/PageHeaderLayout'
    import ApeTable from '@/components/ApeTable';
    import elTableInfiniteScroll from 'el-table-infinite-scroll';
    import ChangeQuestionBank from '@/components/ChangeQuestionBank'
    import {mapGetters} from 'vuex'
    import SearchMixins from '../mixins/SearchMixins';
    import ArticlePickDialog from './ArticlePickDialog'

    const NO_DEL = "no_del";
    const DEL = "del";
    const IS_SUCCESS = "ok";
    export default {
        components: {
            PageHeaderLayout,
            ApeTable,
            ChangeQuestionBank,
            ArticlePickDialog
        },
        mixins: [SearchMixins],
        directives: {
            'el-table-infinite-scroll': elTableInfiniteScroll
        },
        data() {
            return {
                loadingStatus: true,
                questionBankQuestionList: [],
                summaryPagingData: {
                    offset: undefined
                },

                existsPagingData: {
                    is_show: true,
                    layout: 'total, sizes, prev, pager, next, jumper',
                    total: 0,
                    offset: 0, // 分页的offset,序号列使用
                },


                //文章数据
                articleColumns: [
                    {
                        title: '封面',
                        type: 'image',
                        value: 'cover_pic_url',
                    },
                    {
                        title: '缩略图',
                        type: 'image',
                        value: 'thumb_pic_url',
                    },
                    {
                        title: '标题',
                        value: [
                            {label: 'ID：', value: 'id'},
                            {label: '标题：', value: 'title_alias', value_alias: 'title'},
                            {label: '作者：', value: 'author_name'},
                        ],
                    },
                    {
                        title: '发布时间',
                        value: 'created_at',
                    }
                ],

                //分页信息
                pagingData: {
                    is_show: true,
                    layout: 'total, sizes, prev, pager, next, jumper',
                    total: 0,
                    offset: 0, // 分页的offset,序号列使用
                },

                questionList: [],
                modelVisible: false,
                isShowCard: false,
                tableSelectQuestionList: [],
                tableSelectList: [],
                tableData: [],
                totalPage: 1,
                cacheId: "",
                // scoreModelVisible: false,//修改分数弹框
                //修改分数和顺序
                modifyData: {
                    score: "",
                    order: ""
                },
                //过滤题目数组
                checkedQuestionList: [],
                totalCount: null,
                page: 1,
                //已经新增的表
                articleAddList: [],
                cId: "",
                // //分页信息
                pagingInfo: {
                    page_size: 10,
                    current_page: 1
                },

                //搜索条件
                searchCondition: {},
                searchParams: {},
                isSearch: false,
                qtqId: "",
                summaryQuestionList: [],
                qbId: "",


                //选择文章对话框配置
                dialogDate: {
                    visible: false,
                    title: "选择文章",
                    width: '50%'
                },

                articleDialogData: {
                    id: ""
                },

            }
        },
        mounted() {
            this.cacheId = new Date().getTime();
            this.cId = this.$route.params.c_id;
            this.qbId = this.$route.params.qb_id;
            // this.resetBusinessTypeList();
            this.resetDailyResourceList(DEL);
            // this.summaryTestPaperQuestion();
        },
        methods: {
            //删除已选题目
             deleteButton(id) {
                this.$confirm('是否删除该已选文章?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async() => {
                    let data = {
                        id: id
                    };
                    let info = await this.$api.deleteDailyResource(data);
                    if (info === IS_SUCCESS) {
                        this.$nextTick(() => {
                            this.resetDailyResourceList(DEL);
                            // this.summaryTestPaperQuestion();
                            this.$message.success("删除成功");
                        })
                    } else {
                        this.$message.error("删除失败")
                    }
                })


            },

            //弹框
            onSelectQuestion() {

                this.dialogDate.visible = true;
                this.$nextTick(()=>{
                    this.$refs['articleDialog'].resetData();
                })
            },

            //已选题目
            async resetDailyResourceList(type) {
                this.loadingStatus = true;
                let pagingInfo = this.$refs['existsApeTable'].getPagingInfo(type);
                if (!this.cId) {
                    this.$message("题型ID不存在");
                    return;
                }

                let data = {
                    page_size: pagingInfo.page_size,
                    current_page: pagingInfo.current_page,
                    c_id: this.cId
                };

                let {list, pages} = await this.$api.getDailyResourceList(data);

                this.$nextTick(() => {

                    this.articleAddList = list;

                });
                this.existsPagingData.total = pages.total;
                this.existsPagingData.offset = pages.offset;
                this.loadingStatus = false
            },

            // //获取类型
            // async resetBusinessTypeList() {
            //     let data = {
            //         qb_id: this.qbId
            //     };
            //     let {list} = await this.$api.getBusinessTypeList(data);
            //     this.$nextTick(() => {
            //         let businessList = [];
            //         for (let key in list.data) {
            //             let item = list.data[key];
            //             let object = {
            //                 "label": item.name,
            //                 "value": item.id,
            //             };
            //             businessList.push(object);
            //         }
            //         this.businessTypeList = businessList;
            //
            //     });
            // },


            //获取题目
            getSelectList(list) {
                this.tableSelectList = list;
            },

            //切换页码
            async switchPaging() {
                this.resetDailyResourceList()
            },

            //弹框确定
            async onDialogConfirm() {
                this.modelVisible = false;

                if (!this.cId) {
                    this.$message("题型Id不能为空");
                    return;
                }
                if (this.tableSelectList.length <= 0) {
                    this.$message("请选择要添加的题目");
                    return;
                }

                let data = {
                    c_id: this.cId,
                    questions: this.tableSelectList
                };

                await this.$api.storeTestPaperQuestion(data);

                this.resetDailyResourceList();
                // this.summaryTestPaperQuestion();
                this.tableSelectList = [];
            },

            //弹框取消
            onDialogCancel() {
                this.tableSelectList = [];
                this.modelVisible = false;
            },

            //重置
            onResetSearchClick() {
                this.searchCondition = {
                    type: "",
                    qbq_id: ""
                };

                this.curQbId = "";
                this.btId = "";
                this.qbName = "";
                this.businessName = "";
                // this.iniTestPaperQuestionPool(DEL);
            },

            //------选择文章相关方法--------
            handleArticleDialogClose() {
                this.dialogDate.visible = false;
            },

            //文章弹框点击
            async handleArticleDialogConfirm(formData) {

                let formDataLen = formData.length;
                let articleLen = this.articleAddList.length;

                if(formDataLen + articleLen > 5){
                    this.$message.error("选择的文章最多不能超过五篇");
                    return;
                }


                if(!this.cId){
                    this.$message("分类不能为空");
                    return;
                }

                let params = {
                    c_id: this.cId,
                    articles: formData
                };


                let info = await this.$api.storeDailyResource(params);
                if (info) {
                    this.$message.success("添加成功");
                } else {
                    this.$message.error("添加失败");
                    return;
                }

                //刷新列表
                // this.$refs['articleDialog'].resetData();
                this.resetDailyResourceList(DEL);
                this.dialogDate.visible = false;
            }
        },
        computed: {
            ...mapGetters(['userPermissions', 'buttonType']),
        }
    }
</script>

<style lang="stylus" scoped>
    .color-primary
        color #1890ff

    .text-over-hide {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .flex-layout {
        display flex
    }

    .margin-top-20 {
        margin-top 20px
    }
</style>