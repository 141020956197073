<template>

</template>

<script>
    export default {
        name: "SearchMixins",
        data() {
            return {
                questionBankList: [],
                qbName: "",
                curQbId: "",
                businessName: "",
                btId: "",
                businessTypeList: [],
                typeList:[],
                //分页信息
                pagingInfo: {
                    page_size: 30,
                    current_page: 1
                },
            }
        },


        mounted() {

            this.$nextTick(() => {
                this.initQuestionBankList();
                this.resetBusinessTypeList();
                this.getQuestionTypeList();
            })
        },

        methods: {
            //获取题库
            async initQuestionBankList() {
                let {list} = await this.$api.getQuestionBankList();
                this.$nextTick(() => {
                    let questionList = [];
                    for (let key in list) {
                        let item = list[key];
                        let object = {
                            "label": item.name,
                            "value": item.id,
                        };
                        questionList.push(object);
                    }
                    this.questionBankList = questionList;

                });
            },


            //获取题型
            async getQuestionTypeList(){
                let data = await this.$api.commonDict('question_category');
                this.typeList = data;
            },

            //获取类型
            async resetBusinessTypeList() {
                let {list} = await this.$api.getBusinessTypeList();
                this.$nextTick(() => {
                    let businessList = [];
                    for (let key in list.data) {
                        let item = list.data[key];
                        let object = {
                            "label": item.name,
                            "value": item.id,
                        };
                        businessList.push(object);
                    }
                    this.businessTypeList = businessList;

                });
            },

            handleQuestionBankSelect(item) {
                this.curQbId = item;
            },

            handleBusinessSelect(item) {
                this.btId = item;
            },

            //选题对话框关闭
            onDialogClose() {
                this.searchCondition = {
                    type: "",
                    qbq_id: ""
                };

                this.curQbId = "";
                this.btId = "";
                this.qbName = "";
                this.businessName = "";

                //分页信息
                this.pagingInfo = {
                    page_size: 30,
                    current_page: 1
                }
            },
        }

    }
</script>

<style scoped>

</style>